import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

function NewsLetter() {
  const [data, setData] = useState({ email: "", result: null })

  const handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(data.email)
    setData({ result })
  }

  const handleChange = e => {
    setData({ email: e.target.value })
  }

  return (
    <div className="md-contact" name="mdcontact">
      <div className="md-contact__container">
        <div className="md-contact__col md-contact__col--left">
          <h2 className="md-contact__title">contact us</h2>
          <p className="md-contact__para">foodrecipes@moroccandelices.com</p>
          <ul className="md-contact__list">
            <li className="md-contact__item">
              <a className="md-contact__link" href="https://www.youtube.com/channel/UCHKpfpFo0PC4unyeuwka8Fg">
                youtube
              </a>
            </li>
          </ul>
        </div>
        <div className="md-contact__col md-contact__col--right">
          <h2 className="md-contact__title">
            subscribe to the best food newsletter
          </h2>
          <div className="md-contact__newsletter">
            <form className="md-contact__form" onSubmit={handleSubmit}>
              <input
                className="md-contact__input"
                type="email"
                name="email"
                id="email"
                placeholder="Your Email"
                value={data.email}
                onChange={handleChange}
              />
              <button className="md-contact__btn" type="submit">
                Send
              </button>
            </form>
          </div>
          {data.result && (
            <p
              className={
                data.result.result === "success"
                  ? "md-contact__msg md-contact__msg--success"
                  : "md-contact__msg md-contact__msg--error"
              }
              dangerouslySetInnerHTML={{ __html: data.result.msg }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default NewsLetter
