import React, { useEffect, useState } from "react"
import Logo from "../../images/svg/logo.svg"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import gsap from "gsap"
import { Link as LinkReactScroll } from "react-scroll"

function Nav() {
  const { pathname } = useLocation()
  const [toggle, setToggle] = useState(false)

  const handleToggle = () => {
    setToggle(toggle => !toggle)
  }

  useEffect(() => {
    let doc = document.documentElement
    let w = window

    let prevScroll = w.scrollY || doc.scrollTop
    let curScroll
    let direction = 0
    let prevDirection = 0

    const checkScroll = function () {
      curScroll = w.scrollY || doc.scrollTop
      if (curScroll > prevScroll) {
        direction = 2
      } else if (curScroll < prevScroll) {
        direction = 1
      }

      if (direction !== prevDirection) {
        toggleHeader(direction, curScroll)
      }

      prevScroll = curScroll
    }

    const toggleHeader = function (direction, curScroll) {
      if (direction === 2 && curScroll > 500) {
        gsap.to(".md-menu", {
          duration: 0.7,
          autoAlpha: 0,
          ease: "power3.out",
        })
        prevDirection = direction
      } else if (direction === 1) {
        gsap.to(".md-menu", {
          duration: 0.7,
          autoAlpha: 1,
          ease: "power3.inOut",
        })
        prevDirection = direction
      }
    }

    window.addEventListener("scroll", checkScroll)
  }, [])
  return (
    <div className="md-menu">
      <div className="md-menu__container">
        <Link to="/">
          <Logo className="md-menu__logo" />
        </Link>
        <div
          className={
            toggle ? `md-menu__toggle md-menu__toggle--open` : `md-menu__toggle`
          }
          onClick={handleToggle}
          aria-hidden="true"
        >
          menu
        </div>
        <div
          className={
            toggle
              ? `md-menu__nav-container md-menu__nav-container--open`
              : `md-menu__nav-container`
          }
        >
          <nav className="md-menu__nav">
            <ul className="md-menu__list">
              <li className="md-menu__item">
                <Link
                  className={
                    pathname === "/"
                      ? `md-menu__link`
                      : `md-menu__link md-menu__link--color`
                  }
                  to="/recipes"
                >
                  Recipes
                </Link>
              </li>
              <li className="md-menu__item">
                <Link
                  className={
                    pathname === "/"
                      ? `md-menu__link`
                      : `md-menu__link md-menu__link--color`
                  }
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li className="md-menu__item">
                <LinkReactScroll
                  className={
                    pathname === "/"
                      ? `md-menu__link`
                      : `md-menu__link md-menu__link--color`
                  }
                  to="mdcontact"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  onClick={() => setToggle(false)}
                  href="#"
                >
                  Contact
                </LinkReactScroll>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Nav
