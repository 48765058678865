import React from "react"
import { Link } from "react-scroll"
function Footer() {
  return (
    <div className="md-footer">
      <div className="md-footer__container">
        <div className="md-footer__ahdc">
          designed & developed by{" "}
          <a href="https://www.alaahassoun.com/">AHDC</a>
        </div>
        <div className="md-footer__cr">
          &copy; {new Date().getFullYear()} all rights reserved - Moroccan
          Delices
        </div>
        <Link
          className="md-footer__backtotop"
          to="mdhero"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          back to top
        </Link>
      </div>
    </div>
  )
}

export default Footer
