import React, { useState, useEffect } from "react"
import NewsLetter from "../../components/newsLetter/NewsLetter"
import Footer from "../footer/Footer"
import Nav from "../nav/Nav"
import Intro from "../../components/intro/Intro"
import { introAnimation } from "../../animations/desktop"
import { useLocation } from '@reach/router'

function Layout({children}) {
  const { pathname } = useLocation();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if(load) {
      introAnimation(setLoad, pathname);
    }
  },[load, pathname])

  if( load === true) {
    return  <Intro/> ;
  }

  return (
    <div className="md-layout">
      <Nav />
      {children}
      <NewsLetter/>
      <Footer />
    </div>
  )
}

export default Layout
