import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

// INTRO ANIMATION
const t1 = gsap.timeline()

export function introAnimation(setLoad, pathname) {
  gsap.from("body", {
    duration: 0.1,
    autoAlpha: 0,
    ease: "none",
    onComplete: () =>
      (document.querySelector(".md-intro").style.display = "flex"),
  })

  t1.from(
    ".md-intro__bg",
    {
      duration: 1,
      width: "0%",
      ease: "power3.out",
    },
    "+=0.1"
  )
    .from(
      ".md-intro__logo",
      {
        duration: 1,
        autoAlpha: 0,
        y: 100,
        ease: "power3.out",
      },
      "-=.5"
    )
    .to(
      ".md-intro__logo",
      {
        duration: 1,
        autoAlpha: 0,
        y: -100,
        ease: "power3.out",
      },
      "+=.1"
    )
    .to(
      ".md-intro__bg",
      {
        duration: 1,
        left: "100%",
        ease: "power3.out",
        onComplete: () => {
          ScrollTrigger.refresh()
          setLoad(false)

          if (pathname === "/") {
            heroAnimation();
          } else if(pathname === "/recipes" || pathname === "/about") {
            customPageAnimation(pathname);
          } else {
            customPageAnimation(pathname);
            postPageAnimation();
          }
        },
      },
      "-=.5"
    )
}

// HERO ANIMATIONS

export function heroAnimation() {
  gsap.from(".md-hero__bg--left", {
    duration: 1,
    delay: 0.1,
    left: "-100%",
    ease: "power3.in",
  })

  gsap.from(".md-hero__bg--right", {
    duration: 1,
    delay: 0.5,
    autoAlpha: 0,
    ease: "power3.in",
    onComplete: () => navAnimation(),
  })

  gsap.from(`.md-hero__title .md-hero__line-text`, {
    duration: 1,
    delay: 1,
    y: "50px",
    stagger: 0.3,
    autoAlpha: 0,
    ease: "power3.out",
  })

  gsap.from(`.md-hero__btn-wrapper .md-link`, {
    duration: 1,
    delay: 1,
    y: "200px",
    stagger: 0.2,
    autoAlpha: 0,
    ease: "power3.out",
  })
}

// NAV ANIMATION

export function navAnimation() {
  gsap.fromTo(
    ".md-menu",
    {
      y: "-100px",
      autoAlpha: 0,
      display: "none",
      ease: "power3.inOut",
    },
    {
      duration: 0.8,
      y: "0px",
      autoAlpha: 1,
      display: "block",
      ease: "power3.out",
    }
  )
}

// POSTS LIST ANIMATION

export function postsListAnimation(type, index, pathname) {
  gsap.fromTo(
    pathname === "/"
      ? `.md-block--${type} .md-posts__item`
      : `.md-posts-list .md-posts__item`,
    {
      autoAlpha: 0,
      y: 80,
    },
    {
      duration: 1,
      autoAlpha: 1,
      y: 0,
      ease: "power3.out",
      stagger: 0.1,
      scrollTrigger: {
        trigger: pathname === "/" ? `.md-block--${type}` : `.md-posts-list`,
        start: "top center+=100",
        toggleActions: "play none none reverse",
        markers: false,
      },
    }
  )
}

// CUSTOM PAGE ANIMATION
export function customPageAnimation(pathname) {
  const date = document.querySelector('.md-page__date');

  gsap.from(".md-page__title", {
    duration: 1,
    autoAlpha: 0,
    y: "-100px",
    ease: "power3.out",
    onComplete: () => navAnimation(),
  })

  if(date) {
    gsap.from(".md-page__date", {
      duration: 1,
      autoAlpha: 0,
      ease: "power3.out",
      delay: 0.5,
    })
  }

  if(pathname === "/about") {
    gsap.from(".md-about__container", {
      duration:1,
      autoAlpha:0,
      y:100,
      ease: "power3.out"
    })
  }

  if(pathname.includes("/recipes/")) {
    gsap.from(".md-post", {
      duration:1,
      autoAlpha:0,
      y:100,
      ease: "power3.out"
    })
  }
}

// POST PAGE ANIMATION
export function postPageAnimation() {

  const postPrev = document.querySelector('.md-posts__post--previous');
  const postNext = document.querySelector('.md-posts__post--next');

  if(postPrev) {
    gsap.fromTo(
      ".md-posts__post--previous",
      {
        autoAlpha: 0,
      },
      {
        duration: 1,
        autoAlpha: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: ".md-posts__post--previous",
          start: "top center+=400",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    )
  }

  if(postNext) {
    gsap.fromTo(
      ".md-posts__post--next",
      {
        autoAlpha: 0,
      },
      {
        duration: 1,
        autoAlpha: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: ".md-posts__post--next",
          start: "top center+=400",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    )
  }
}
