import React from "react"
import Logo from "../../images/svg/logo.svg"

export default function Intro() {
  return (
    <div className="md-intro">
      <div className="md-intro__bg"></div>
      <Logo className="md-intro__logo" />
    </div>
  )
}
